import React from "react";
import './pastProjects.css';
import {gsap} from "gsap";
import {Link} from "react-router-dom";
import FadeInSection from "../FadeInSection";
import drupal from '../videos/drupalhover.mp4';
import springwater from '../videos/springwaterhover.mp4';
import asu from '../videos/asuhover.mp4';
import crystal from '../videos/crystalhover.mp4';


const PastProjectsHome = () => {

    const items = document.querySelectorAll('.item')

    items.forEach((el) => {
        const image = el.querySelector('img')

        el.addEventListener('mouseenter', (e) => {
            gsap.to(image, {autoAlpha: 1})
        })

        el.addEventListener('mouseleave', (e) => {
            gsap.to(image, {autoAlpha: 0})
        })

        el.addEventListener('mousemove', (e) => {
            gsap.set(image, {x: e.offsetX - 200})
        })
        el.addEventListener('mousemove', (e) => {
            gsap.set(image, {y: e.offsetY + 100})
        })

    })

    return (
        <>
            <FadeInSection >
                <div className='recent-project-container'>
                    <div className="past-projects-left">Past Projects</div>

                    <div className="list-past-projects">
                        <div className="index-sections">
                            <div className="index">

                                <a href="http://keyhole-001-site2.ctempurl.com/"
                                   target='_blank'
                                   rel="noopener">
                                    <div className="index__item">
                        <span className="index__item-text">
                            <span className="index__item-textinner">Custom Drupal Themes
                          <hr/>   <span className="past-skills-text"> &nbsp; &nbsp; PHP / Javascript / Design
                       <span className="reveal drupaltop">
                             <video autoPlay muted loop>
                                                    <source src={drupal} type="video/mp4"/>
                                                </video>
                       </span></span></span></span>
                                    </div>
                                </a>

                                <a href="https://www.springwater.simpledzn.com/"
                                   target='_blank'
                                   rel="noopener">
                                    <div className="index__item">
                            <span className="index__item-text"><span className="index__item-textinner">Springwater Preschool
                         <hr/> <span className="past-skills-text"> &nbsp; &nbsp; ASP.NET / SQL / Design
                       <span className="reveal springtop">
                             <video autoPlay muted loop>
                                                    <source src={springwater} type="video/mp4"/>
                                                </video>
                       </span></span></span></span>
                                    </div>
                                </a>

                                    <a href="http://keyhole-001-site12.ctempurl.com/"
                                       target='_blank'
                                       rel="noopener">
                                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">ASU Concrete Canoe
                        <hr/>   <span className="past-skills-text"> &nbsp; &nbsp; Design / Development
                                          <span className="reveal asutop">
                             <video autoPlay muted loop>
                                                    <source src={asu} type="video/mp4"/>
                                                </video>
                       </span></span></span></span>
                                    </div>
                                </a>

                                <a href="https://www.crystal.simpledzn.com/"
                                   target='_blank'
                                   rel="noopener">
                                    <div className="index__item">
                            <span className="index__item-text"><span className="index__item-textinner">Crystal Remodeling
          <hr/>   <span className="past-skills-text"> &nbsp; &nbsp; Design / Development
                       <span className="reveal crystaltop">
                             <video autoPlay muted loop>
                                                    <source src={crystal} type="video/mp4"/>
                                                </video>
                       </span></span></span></span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </FadeInSection>
        </>
    )
}

export default PastProjectsHome